
import ListBlankCourier from '@/views/master/courier/components/ListBlankCourier.vue'
import ComissionAdjustmentModal from '@/views/master/courier/comission-adjustment/ComissionAdjustmentModal.vue'
import {
  onMounted,
  reactive,
  ref
} from 'vue'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  alpha
} from '@vuelidate/validators'
import {
  getListAgent,
  comissionAdjustmentUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'

export default {
  components: {
    ListBlankCourier,
    ComissionAdjustmentModal
  },
  setup() {
    const isShowDialog = ref(false)
    const store = useStore()
    const inputForm = reactive({
      inputCourier: '',
      inputAgent: null
    })
    const isShowTable = ref(false)
    const dataFormAdjustment = ref(null)
    const agentOptions = ref([])
    const dataAdjustment = ref([])
    const submitted = ref(false)
    const rules = {
      inputCourier: {
        required: helpers.withMessage('Nama Kurir harus diisi', required),
      },
      inputAgent: {
        required: helpers.withMessage('Agen harus diisi', required),
      }
    }
    const v$ = useVuelidate(rules, inputForm)

    const hideDialog = () => {
      isShowDialog.value = false
      dataFormAdjustment.value = null
    }

    const getAgents = async () => {
      const response = await getListAgent({
        select: '&$select=Id,Kode,Nama,KotaId',
        expand: '&$expand=Kota($select=Id,Nama,Kode)'
      })
      if (!response.error) {
        agentOptions.value = response.result.map((item: any) => ({
          label: `${item.Nama} (${item.Kota.Nama})`,
          nama: item.Nama,
          value: item.Id
        }))
      }
    }

    const getDataAdjustment = () => {
      store.dispatch('showLoading')
      comissionAdjustmentUseCase.getAll({
        custom: `?AgenId=${inputForm.inputAgent}&NamaKurir=${inputForm.inputCourier.toLowerCase()}`
      }).then((response: any) => {
        if (!response.error) {
          dataAdjustment.value = response.result.Data.map((x: any) => {
            x.Saldo = x.Saldo === null ? 0 : x.Saldo
            return x
          })
          isShowTable.value = true
        }
        store.dispatch('hideLoading')
      })
    }

    const reloadData = () => {
      getDataAdjustment()
      hideDialog()
    }

    const searchData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        // eslint-disable-next-line no-useless-return
        return
      }
      getDataAdjustment()
    }

    const customizeData = (val: any) => {
      dataFormAdjustment.value = val
      isShowDialog.value = true
    }

    onMounted(() => {
      getAgents()
    })
    return {
      isShowDialog,
      hideDialog,
      inputForm,
      v$,
      submitted,
      searchData,
      agentOptions,
      isShowTable,
      dataAdjustment,
      customizeData,
      dataFormAdjustment,
      reloadData
    }
  },
}
