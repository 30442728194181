
/* eslint-disable vue/no-dupe-keys */
import {
  computed,
  reactive,
  ref,
  getCurrentInstance
} from 'vue'
import {
  environment
} from '@/utils'
import ComissionAdjustmentForm from '@/views/master/courier/comission-adjustment/ComissionAdjustmentForm.vue'
import ComissionAdjustmentHistory from '@/views/master/courier/comission-adjustment/ComissionAdjustmentHistory.vue'
import {
  comissionAdjustmentUseCase
} from '@/domain/usecase'

export default {
  name: 'ComissionAdjustmentModal',
  components: {
    ComissionAdjustmentForm,
    ComissionAdjustmentHistory
  },
  props: {
    isShowDialog: {
      default: () => false
    },
    dataForm: {
      default: () => Object
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd,
    } = app!.appContext.config.globalProperties
    const API_BASE = environment.getApiBase()
    const hideDialog = () => {
      emit('hideDialog')
    }
    const propertiesProps = reactive(props)
    const isShowModal = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          hideDialog()
        }
      }
    })
    const dataForm = ref(propertiesProps.dataForm)
    const indexTab = ref(0)
    const refComissionAdjustmentForm = ref()
    const tabsHeader = ref([{
      value: 0,
      title: 'Penyesuaian'
    },
    {
      value: 1,
      title: 'Riwayat Penyesuaian'
    }
    ])
    const reloadData = () => {
      const tempRefDataForm = refComissionAdjustmentForm.value
      comissionAdjustmentUseCase.submitData(null, {
        KurirMsUserId: dataForm.value.KurirId,
        Nominal: tempRefDataForm.positionFilter === 1 ? -Math.abs(tempRefDataForm.form.numberSaldo) : tempRefDataForm.form.numberSaldo,
        Keterangan: tempRefDataForm.form.reason,
        Attribute1: 'adjustment',
        Attribute2: null,
        Attribute3: null,
      }).then((response: any) => {
        if (!response.error) {
          $toast.add({
            severity: 'success',
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 3000
          })
          emit('reloadData')
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
      })
    }
    const submit = (val: any) => {
      refComissionAdjustmentForm.value.submit(val)
    }
    return {
      isShowModal,
      API_BASE,
      tabsHeader,
      indexTab,
      dataForm,
      reloadData,
      submit,
      refComissionAdjustmentForm,
      hideDialog
    }
  }
}
