
/* eslint-disable vue/no-dupe-keys */
import TabsFormDelivery from '@/views/transactions/delivery-item/create/steps-2/components/TabsFormDelivery.vue'
import {
  ref,
  reactive,
  toRefs
} from 'vue'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'

export default {
  name: 'ComissionAdjustmentForm',
  components: {
    TabsFormDelivery
  },
  props: {
    dataForm: {
      default: () => Object
    }
  },
  emits: ['reloadData'],
  setup(props: any, { emit }: any) {
    const tabsOptions = ref([{
      label: 'Pengurangan',
      value: 1
    },
    {
      label: 'Penambahan',
      value: 2
    }
    ])
    const form = reactive({
      reason: null,
      numberSaldo: 0
    })
    const positionFilter = ref(1)
    const {
      dataForm
    } = toRefs(props)

    const changePosition = (val: any) => {
      positionFilter.value = val.value
    }
    const submitted = ref(false)
    const rules = {
      reason: {
        required: helpers.withMessage('Alasan Penyesuaian harus diisi', required),
      }
    }
    const v$ = useVuelidate(rules, form)

    const processSubmit = () => {
      emit('reloadData')
    }

    const submit = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      processSubmit()
    }

    return {
      tabsOptions,
      changePosition,
      positionFilter,
      dataForm,
      form,
      submitted,
      v$,
      rules,
      submit
    }
  }
}
