
import {
  toRefs
} from 'vue'
import moment from 'moment'

export default {
  name: 'CardComissionHistory',
  props: {
    data: {
      default: () => Object
    }
  },
  setup(props: any) {
    const {
      data
    } = toRefs(props)
    const returnTypeComission = (val: any) => {
      // console.log('any val', val)
      let titleComission
      let colorComission
      let imgComission
      let isAdded
      if (val.Attribute1 === 'komisi') {
        titleComission = 'Komisi Pengiriman'
        colorComission = 'text-4CA'
        imgComission = require('@/assets/img/icon/type-komisi/indonesian-rupiah.png')
        isAdded = '+'
      } else if (val.Attribute1 === 'bonus') {
        titleComission = 'Bonus Pengiriman'
        colorComission = 'text-4CA'
        imgComission = require('@/assets/img/icon/type-komisi/bonus.png')
        isAdded = '+'
      } else if (val.Attribute1 === 'payout') {
        titleComission = 'Komisi Telah Dikirimkan'
        colorComission = 'color-red'
        imgComission = require('@/assets/img/icon/type-komisi/money21.png')
        isAdded = ''
      } else if (val.Attribute1 === 'denda') {
        titleComission = 'Denda'
        colorComission = 'color-red'
        imgComission = require('@/assets/img/icon/type-komisi/denda-komisi.png')
        isAdded = ''
      } else if (val.Attribute1 === 'adjustment') {
        titleComission = 'Penyesuaian Komisi'
        colorComission = val.Nominal >= 0 ? 'text-4CA' : 'color-red'
        imgComission = require('@/assets/img/icon/type-komisi/calculator-adjs.png')
        isAdded = ''
      } else if (val.Attribute1 === 'return') {
        titleComission = 'Pengembalian Komisi'
        colorComission = val.Nominal >= 0 ? 'text-4CA' : 'color-red'
        imgComission = require('@/assets/img/icon/type-komisi/money-back.png')
        isAdded = ''
      }
      return {
        titleComission,
        colorComission,
        imgComission,
        isAdded
      }
    }
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      data,
      returnTypeComission,
      moment
    }
  }
}
