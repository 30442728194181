import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex w-full border-blue-greyDF py-3" }
const _hoisted_2 = { class: "container-icon px-4" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: " grid grid-cols-3 items-center" }
const _hoisted_5 = { class: " col-span-2" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: " font-bold" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "section-sub-list" }
const _hoisted_10 = {
  key: 0,
  class: " text-xs"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = {
  class: "text-xs text-right",
  style: {"color":"#757575"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("img", {
        src: $setup.returnTypeComission($setup.data).imgComission,
        class: "w-10 h-10"
      }, null, 8, ["src"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, [
            _createVNode("p", _hoisted_7, _toDisplayString($setup.returnTypeComission($setup.data).titleComission), 1)
          ]),
          ($setup.data?.KurirTransUserPengiriman)
            ? (_openBlock(), _createBlock("div", _hoisted_8, [
                _createVNode("div", _hoisted_9, [
                  ($setup.data.Attribute1 === 'adjustment' || $setup.data.Attribute1 === 'return')
                    ? (_openBlock(), _createBlock("p", _hoisted_10, _toDisplayString($setup.data?.Keterangan), 1))
                    : (_openBlock(), _createBlock("ul", _hoisted_11, [
                        ($setup.data?.KurirTransUserPengiriman?.TransPengirimanHd)
                          ? (_openBlock(), _createBlock("li", _hoisted_12, _toDisplayString($setup.data?.KurirTransUserPengiriman?.TransPengirimanHd?.Resi), 1))
                          : _createCommentVNode("", true),
                        ($setup.data?.KurirTransUserPengiriman?.KurirMsJenisKendaraan && $setup.data.Attribute1 !== 'denda')
                          ? (_openBlock(), _createBlock("li", _hoisted_13, _toDisplayString($setup.data?.KurirTransUserPengiriman?.KurirMsJenisKendaraan?.Nama), 1))
                          : _createCommentVNode("", true),
                        ($setup.data?.KurirTransUserPengiriman?.KurirTipePengirimanId === 1 && $setup.data.Attribute1 !== 'denda')
                          ? (_openBlock(), _createBlock("li", _hoisted_14, _toDisplayString('ANTAR')))
                          : _createCommentVNode("", true),
                        ($setup.data?.KurirTransUserPengiriman?.KurirTipePengirimanId === 0 && $setup.data.Attribute1 !== 'denda')
                          ? (_openBlock(), _createBlock("li", _hoisted_15, _toDisplayString('JEMPUT')))
                          : _createCommentVNode("", true)
                      ]))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode("div", null, [
          _createVNode("div", null, [
            _createVNode("p", {
              class: [" font-bold text-right", $setup.returnTypeComission($setup.data).colorComission]
            }, _toDisplayString(`Rp. ${$setup.returnTypeComission($setup.data).isAdded}${new Number($setup.data.Nominal).toLocaleString("id-ID")}`), 3)
          ]),
          _createVNode("div", null, [
            _createVNode("p", _hoisted_16, _toDisplayString(`${$setup.moment($setup.data.CreateDate).format('DD MMMM YYYY')} - ${$setup.moment($setup.data.CreateDate).format('HH:mm')}`), 1)
          ])
        ])
      ])
    ])
  ]))
}